import _ from 'lodash';
import { useMemo, useRef, useState, memo, FC, useEffect } from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, areEqual } from 'react-window';
import { v4 as uuid } from 'uuid';
import { IdRecord, StyleRecord } from '../types/UtilTypes';

export type InfiniteListItemComponent = FC<{ key: string; item: InfiniteListItem | InfiniteListItemAlt | string; style: StyleRecord; index: number }>;

const StyledListWrapper = styled.div`
    width: 100%;
    position: relative;
    .listAutosizer > div {
        width: 100% !important;
    }
`;

export interface InfiniteListItem {
    id: string;
    [x: string]: any;
}

export interface InfiniteListItemAlt {
    value: string;
    [x: string]: any;
}

interface InfiniteListProps {
    items: IdRecord<InfiniteListItem> | IdRecord<InfiniteListItemAlt>;
    itemHeight: number;
    style?: StyleRecord;
    listItem: InfiniteListItemComponent;
}

export const InfiniteList = (props: InfiniteListProps) => {
    const infiniteLoaderRef = useRef(null);
    const { listItem: ListItem, items } = props;
    const nItems = Object.keys(items).length;
    const itemConv = useMemo(() => {
        const keys = Object.keys(items);
        const conv = {};
        keys.forEach((key, index) => {
            conv[index] = key;
        });
        return conv;
    }, [items]);

    const [rowCount, setRowCount] = useState(0);
    const [itemsLoaded, setItemsLoaded] = useState({});

    const isRowLoaded = ({ index }) => {
        return itemsLoaded[index] !== undefined;
    };

    const loadMoreRows = ({ startIndex, stopIndex }) => {
        const increment = stopIndex - startIndex + 1;

        for (let i = startIndex; i <= stopIndex; i++) {
            setItemsLoaded({ ...itemsLoaded, [i]: itemConv[i] });
        }
        setRowCount(rowCount + increment);

        return new Promise((resolve) => {
            resolve(true);
        });
    };

    // These components will be re-rendered multiple times because the `scrollOffset state of the
    // list changes, so to stop that we will memoize the child components so they don't re-render.
    const rowRenderer = memo(({ index, style }: { index: number; style: StyleRecord }) => {
        const item = items[itemConv[index]];
        return <ListItem key={item.id ?? item.value} item={item} style={style} index={index} />;
    }, areEqual);

    return (
        <StyledListWrapper style={props.style}>
            <AutoSizer style={{ height: '100%', width: '100%' }} className="listAutosizer">
                {({ width, height }) => (
                    <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreItems={loadMoreRows} rowCount={nItems} ref={infiniteLoaderRef}>
                        {({ onItemsRendered, ref }) => (
                            <List
                                style={{ overflow: 'overlay' }}
                                ref={ref}
                                height={height}
                                onItemsRendered={onItemsRendered}
                                itemCount={nItems}
                                itemSize={props.itemHeight}
                                width={width}
                            >
                                {rowRenderer}
                            </List>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </StyledListWrapper>
    );
};

interface InfiniteListArrayProps {
    items: InfiniteListItem[] | InfiniteListItemAlt[] | string[];
    itemHeight: number;
    style?: StyleRecord;
    listItem: InfiniteListItemComponent;
}

export const InfiniteListArray = (props: InfiniteListArrayProps) => {
    const infiniteLoaderRef = useRef(null);
    const { listItem: ListItem, items } = props;
    const nItems = items.length;

    const [rowCount, setRowCount] = useState(0);
    const [itemsLoaded, setItemsLoaded] = useState({});

    const isRowLoaded = ({ index }) => {
        return itemsLoaded[index] !== undefined;
    };

    const loadMoreRows = ({ startIndex, stopIndex }) => {
        const increment = stopIndex - startIndex + 1;

        for (let i = startIndex; i <= stopIndex; i++) {
            const rowItem = items[i];
            setItemsLoaded({ ...itemsLoaded, [i]: typeof rowItem === 'string' ? rowItem : rowItem.id ?? rowItem.value });
        }
        setRowCount(rowCount + increment);

        return new Promise((resolve) => {
            resolve(true);
        });
    };

    const rowRenderer = ({ index, style }: { index: number; style: StyleRecord }) => {
        const item = items[index];
        return <ListItem key={typeof item === 'string' ? uuid() : item.id ?? item.value} item={item} style={style} index={index} />;
    };

    return (
        <StyledListWrapper style={props.style}>
            <AutoSizer style={{ height: '100%', width: '100%' }} className="listAutosizer">
                {({ width, height }) => (
                    <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreItems={loadMoreRows} rowCount={nItems} ref={infiniteLoaderRef}>
                        {({ onItemsRendered, ref }) => (
                            <List
                                style={{ overflow: 'overlay' }}
                                ref={ref}
                                height={height}
                                onItemsRendered={onItemsRendered}
                                itemCount={nItems}
                                itemSize={props.itemHeight}
                                width={width}
                            >
                                {rowRenderer}
                            </List>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </StyledListWrapper>
    );
};
