import { useCallback, useMemo, useState } from 'react';
import { DateInputValue } from '../form/FormTypes';

export function useAddProduct() {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>(undefined);

    const onClickAddProduct = useCallback(
        async (
            data: {
                name: string;
                description: string;
                img: string;
                site: string;
                retail: string;
                retailCurrency: string;
                resell: string;
                resellCurrency: string;
                resellLink: string;
                highlighted: boolean;
            },
            taskData: any,
            date: DateInputValue,
            endDate: DateInputValue
        ) => {
            try {
                if (loading) return;
                setLoading(true);
                setSuccess(undefined);
                const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/cg/product`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...data,
                        taskData: taskData,
                        date: date,
                        endDate: endDate,
                    }),
                });
                const body = (await res.json()) as { success: boolean; error?: string };
                if (!body?.success) {
                    setError(new Error(body?.error));
                } else {
                    setSuccess(`"${data?.name}" added!`);
                }
            } catch (err) {
                setError(new Error(`Failed to add product: ${err?.message}`));
            } finally {
                setLoading(false);
            }
        },
        [loading, history]
    );

    return useMemo(
        () => ({
            onClickAddProduct,
            loading,
            error,
            success,
        }),
        [onClickAddProduct, loading, error, success]
    );
}
