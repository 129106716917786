import { useCallback, useEffect, useMemo, useState } from 'react';
import { CookGroupProduct } from '../types/CookGroupTypes';

export function useDeleteProduct() {
    const [products, setProducts] = useState<CookGroupProduct[] | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>(undefined);

    const onClickDeleteProduct = useCallback(
        async (id: string, name: string) => {
            try {
                if (loading) return;
                setLoading(true);
                setSuccess(undefined);
                const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/cg/product/${id}`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                });
                const body = (await res.json()) as { success: boolean; data?: CookGroupProduct[]; error?: string };
                if (!body?.success) {
                    setError(new Error(body?.error));
                } else {
                    setProducts(body?.data);
                    setSuccess(`"${name}" deleted!`);
                }
            } catch (err) {
                setError(new Error(`Failed to delete product: ${err?.message}`));
            } finally {
                setLoading(false);
            }
        },
        [loading, history]
    );

    const fetchProducts = useCallback(async () => {
        try {
            if (loading) return;
            setLoading(true);
            setSuccess(undefined);
            const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/cg/products`, {
                method: 'GET',
                credentials: 'include',
                headers: {},
            });
            const body = (await res.json()) as { success: boolean; data?: CookGroupProduct[]; error?: string };
            if (!body?.success) {
                setError(new Error(body?.error));
            } else {
                setProducts(body?.data);
            }
        } catch (err) {
            setError(new Error(`Failed to retrieve products: ${err?.message}`));
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, []);

    return useMemo(
        () => ({
            onClickDeleteProduct,
            loading,
            error,
            success,
            products,
        }),
        [onClickDeleteProduct, loading, error, success, products]
    );
}
