import styled from 'styled-components';
import { ReactNode } from 'react';
import { StyleRecord } from '../types/UtilTypes';
import { GridRow } from '../components/Grid';

const StyledFormRow = styled(GridRow)`
    margin-bottom: 20px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

interface FormRowProps {
    children: ReactNode;
    columns: string;
    style?: StyleRecord;
}

export const FormRow = ({ children, columns, style }: FormRowProps) => {
    return <StyledFormRow style={{ gridTemplateColumns: columns, columnGap: 10, ...(style ?? {}) }}>{children}</StyledFormRow>;
};
