import _, { replace } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Text } from './components/Text';
import { FormRow } from './form/FormRow';
import { Input } from './form/Input';
import { useAddProduct } from './hooks/useAddProduct';
import { useDeleteProduct } from './hooks/useDeleteProduct';
import { DateInput } from './form/DateInput';
import { DateInputValue, SelectOption } from './form/FormTypes';
import { ButtonColor, ButtonColorTypes } from './components/Button';
import { Select } from './form/Select';
import { StatusComponent } from './components/StatusComponent';
import { LoadingDots } from './components/LoadingDots';
import { SiteEnumOptions } from './types/SiteTypes';
import { naturalSort } from './scripts/scripts_string';
import { dateInputSmallerThan } from './scripts/scripts_date';
import { useProducts } from './hooks/useProducts';
import { CurrencyEnumOptions, CurrencyTypes } from './types/CurrencyTypes';
import { Switch } from './form/Switch';

interface ProductsProps {}
export function Products(props: ProductsProps) {
    const x = useAddProduct();
    const { products, productsLoading: productsLoading, productsError: productsError } = useProducts();
    const { success, loading, error, onClickDeleteProduct } = useDeleteProduct();

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [img, setImg] = useState<string>('');
    const [site, setSite] = useState<string>('');

    const [retail, setRetail] = useState<string>('');
    const [resell, setResell] = useState<string>('');
    const [retailCurrency, setRetailCurrency] = useState<string>(CurrencyTypes.EUR);
    const [resellCurrency, setResellCurrency] = useState<string>(CurrencyTypes.EUR);
    const [resellLink, setResellLink] = useState<string>('');

    const [highlighted, setHighlighted] = useState<boolean>(false);

    const [sizes, setSizes] = useState<string[]>(['']);
    const [monitorInput, setMonitorInput] = useState<string>('');
    const [date, setDate] = useState<DateInputValue>({
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
    });
    const [endDate, setEndDate] = useState<DateInputValue>({
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
    });
    const [errors, setErrors] = useState<Record<string, string>>({});

    const [productToDelete, setProductToDelete] = useState<string>('');

    const productOptions = useMemo(() => {
        const defaultOptions = [{ value: '', text: 'Select a product' }];
        if (!_.isArray(products)) return defaultOptions;
        return [...defaultOptions, ...products?.map((v) => ({ value: v.id, text: `${SiteEnumOptions.find((s) => s.value === v.site)?.text} - ${v.name}` }))];
    }, [products]);

    const siteOptions = useMemo(() => {
        const defaultOptions = [{ value: '', text: 'Select a site' }];
        return [...defaultOptions, ...SiteEnumOptions.sort((a, b) => naturalSort.compare(a.text, b.text))];
    }, []);

    const getDateFromInput = (v: DateInputValue) => {
        return new Date(v.year, v.month, v.date, 0, 0, 0, 0);
    };

    useEffect(() => {
        const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
        const v = {};
        if (dateInputSmallerThan(date, endDate)) {
            v['endDate'] = 'Product End Date cannot be smaller than the release date!';
        }
        if (img && !img.match(urlRegex)) {
            v['img'] = 'Please enter a valid image URL!';
        }
        setErrors(v);
    }, [date, endDate, img]);

    return (
        <>
            <FormRow columns="1fr">
                <Text color="MAIN_FG" size="min(3.6vw, 1.8rem)" weight="700">
                    Task Info
                </Text>
            </FormRow>

            <FormRow columns="1fr">
                <Select value={site} onChange={setSite} options={siteOptions} />
            </FormRow>

            <FormRow columns="1fr">
                <Input label="Monitor Input" value={monitorInput} onChange={setMonitorInput} placeholder="PID/Link" />
            </FormRow>

            <FormRow columns="1fr">
                <Text color="MAIN_FG" size="min(3.6vw, 1.8rem)" weight="700">
                    Product Info
                </Text>
            </FormRow>

            <FormRow columns="1fr">
                <Input
                    label="Product Name"
                    labelOptional="(Limited to 20 Chars)"
                    value={name}
                    onChange={setName}
                    placeholder="Yeezy Slides Yellow"
                    onChangeMap={(v) => v.slice(0, 20)}
                />
            </FormRow>

            <FormRow columns="1fr">
                <Input
                    label="Product Description"
                    labelOptional="(Optional - Limited to 40 Chars)"
                    value={description}
                    onChange={setDescription}
                    placeholder="...."
                    onChangeMap={(v) => v.slice(0, 40)}
                />
            </FormRow>

            <FormRow columns="1fr">
                <Input label="Product Image" labelOptional="(Optional)" value={img} onChange={setImg} placeholder="URL" error={errors['img']} />
            </FormRow>

            <FormRow columns="1fr">
                <Switch label="Highlighted Release" value={highlighted} onChange={setHighlighted} />
            </FormRow>

            <FormRow columns="1fr 80px">
                <Input
                    label="Retail Price"
                    labelOptional="(Optional)"
                    value={retail}
                    onChange={setRetail}
                    placeholder="1000.00"
                    onChangeMap={(v) => {
                        let replacedValue = v.replace(/[^0-9\.]/g, '');
                        replacedValue = replacedValue.replace(/[.](?=.*[.])/g, '');
                        return replacedValue;
                    }}
                />
                <Select value={retailCurrency} onChange={setRetailCurrency} options={CurrencyEnumOptions} style={{ marginTop: 'auto' }} />
            </FormRow>

            <FormRow columns="1fr 80px">
                <Input
                    label="Resell"
                    labelOptional="(Optional)"
                    value={resell}
                    onChange={setResell}
                    placeholder="1500.00"
                    onChangeMap={(v) => {
                        let replacedValue = v.replace(/[^0-9\.]/g, '');
                        replacedValue = replacedValue.replace(/[.](?=.*[.])/g, '');
                        return replacedValue;
                    }}
                />
                <Select value={resellCurrency} onChange={setResellCurrency} options={CurrencyEnumOptions} style={{ marginTop: 'auto' }} />
            </FormRow>

            <FormRow columns="1fr">
                <Input label="Resell Link" labelOptional="(Optional)" value={resellLink} onChange={setResellLink} placeholder="https://stockx.com/..." />
            </FormRow>

            <FormRow columns="1fr">
                <DateInput dropdownDisabled={true} label="Product Date" value={date} onChange={setDate} />
            </FormRow>

            <FormRow columns="1fr">
                <DateInput dropdownDisabled={true} label="Product End Date" value={endDate} onChange={setEndDate} error={errors['endDate']} />
            </FormRow>

            <FormRow columns="1fr">
                <ButtonColor
                    color={ButtonColorTypes.DEFAULT}
                    onClick={() =>
                        x.onClickAddProduct(
                            { site, name, description, img, retail, retailCurrency, resell, resellCurrency, resellLink, highlighted },
                            { monitorInput, sizes },
                            date,
                            endDate
                        )
                    }
                    disabled={x.loading || !name || !site || !date || !endDate || !monitorInput}
                >
                    Add
                </ButtonColor>
            </FormRow>
            {!x.loading && <StatusComponent success={x.success} error={x.error} />}

            <FormRow columns="1fr">
                <Text color="MAIN_FG" size="min(3vw, 1.8rem)" weight="700">
                    Delete a product
                </Text>
            </FormRow>

            {!productsLoading && products && (
                <FormRow columns="1fr">
                    <Select value={productToDelete} onChange={setProductToDelete} options={productOptions} />
                </FormRow>
            )}
            {productsLoading && (
                <FormRow columns="1fr">
                    <LoadingDots dotColor="MAIN_FG_THEME" style={{ margin: 0 }} />
                </FormRow>
            )}
            {!productsLoading && <StatusComponent error={productsError} />}
            {!productsLoading && products && (
                <FormRow columns="1fr">
                    <ButtonColor
                        color={ButtonColorTypes.DEFAULT}
                        onClick={() => onClickDeleteProduct(productToDelete, products.find((v) => v?.id === productToDelete)?.name)}
                        disabled={loading || !productToDelete || !_.isEqual(errors, {})}
                    >
                        Delete
                    </ButtonColor>
                </FormRow>
            )}
            {!loading && <StatusComponent success={success} error={error} />}
        </>
    );
}
