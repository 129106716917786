import styled from 'styled-components';
import { MouseEvent, ReactNode } from 'react';
import { StyleRecord } from '../types/UtilTypes';

const StyledIcon = styled.div<{ scaleIconWidth: boolean; colorFill: string; colorStroke: string; colorFillHover: string; colorStrokeHover: string }>`
    svg {
        width: ${(props) => (props.scaleIconWidth === true ? '100%' : 'auto')};
        height: ${(props) => (props.scaleIconWidth === true ? 'auto' : '100%')};
        display: block;
        ${(props) => (props.colorFill ? `fill: ${props.theme[props.colorFill]};` : '')}
        ${(props) => (props.colorStroke ? `stroke: ${props.theme[props.colorStroke]};` : '')}
    }
    &:hover {
        svg {
            ${({ theme, colorFillHover }) => (colorFillHover ? `fill: ${theme[colorFillHover]};` : '')}
            ${({ theme, colorStrokeHover }) => (colorStrokeHover ? `stroke: ${theme[colorStrokeHover]};` : '')}
        }
    }
`;
interface IconProps {
    colorStroke?: string;
    colorFill?: string;
    colorStrokeHover?: string;
    colorFillHover?: string;
    style?: StyleRecord;
    children?: ReactNode;
    scaleIconWidth?: boolean;
    onClick?: (e: MouseEvent) => void;
    onMouseEnter?: (e: MouseEvent) => void;
    onMouseLeave?: (e: MouseEvent) => void;
}
export function Icon(props: IconProps) {
    const { colorStroke, colorStrokeHover, colorFill, colorFillHover, style, children, onClick, scaleIconWidth, onMouseEnter, onMouseLeave } = props;
    return (
        <StyledIcon
            scaleIconWidth={scaleIconWidth}
            colorStroke={colorStroke}
            colorStrokeHover={colorStrokeHover}
            colorFill={colorFill}
            colorFillHover={colorFillHover}
            style={style && style}
            onClick={(e) => onClick && onClick(e)}
            onMouseEnter={onMouseEnter && onMouseEnter}
            onMouseLeave={onMouseLeave && onMouseLeave}
        >
            {children}
        </StyledIcon>
    );
}
