import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Text } from './components/Text';
import { FormRow } from './form/FormRow';
import { ButtonColor, ButtonColorTypes } from './components/Button';
import { Select } from './form/Select';
import { StatusComponent } from './components/StatusComponent';
import { LoadingDots } from './components/LoadingDots';
import { SiteEnumOptions } from './types/SiteTypes';
import { useProducts } from './hooks/useProducts';
import { useRemoteProduct } from './hooks/useRemoteProduct';

interface ProductsProps {}
export function ProductsRemote(props: ProductsProps) {
    const { products, productsLoading, productsError, productsSuccess } = useProducts();
    const { onClickStartProduct, onClickStopProduct, remoteProductLoading, remoteProductError, remoteproductSuccess, remoteProductResult } = useRemoteProduct();

    const [productToRemote, setProductToRemote] = useState<string>('');

    const productOptions = useMemo(() => {
        const defaultOptions = [{ value: '', text: 'Select a product' }];
        if (!_.isArray(products)) return defaultOptions;
        return [...defaultOptions, ...products?.map((v) => ({ value: v.id, text: `${SiteEnumOptions.find((s) => s.value === v.site)?.text} - ${v.name}` }))];
    }, [products]);

    return (
        <>
            <FormRow columns="1fr">
                <Text color="MAIN_FG" size="min(3vw, 1.8rem)" weight="700">
                    Product Remote
                </Text>
            </FormRow>
            {!productsLoading && products && (
                <FormRow columns="1fr">
                    <Select value={productToRemote} onChange={setProductToRemote} options={productOptions} />
                </FormRow>
            )}
            {productsLoading && (
                <FormRow columns="1fr">
                    <LoadingDots dotColor="MAIN_FG_THEME" style={{ margin: 0 }} />
                </FormRow>
            )}
            {!productsLoading && <StatusComponent error={productsError} />}
            {!productsLoading && products && (
                <FormRow columns="1fr 1fr">
                    <ButtonColor
                        color={ButtonColorTypes.DEFAULT}
                        onClick={() => onClickStartProduct(products.find((v) => v?.id === productToRemote))}
                        disabled={remoteProductLoading || !productToRemote}
                    >
                        Start
                    </ButtonColor>
                    <ButtonColor
                        color={ButtonColorTypes.DEFAULT}
                        onClick={() => onClickStopProduct(products.find((v) => v?.id === productToRemote))}
                        disabled={remoteProductLoading || !productToRemote}
                    >
                        Stop
                    </ButtonColor>
                </FormRow>
            )}
            {!remoteProductLoading && (
                <FormRow columns="1fr">
                    <StatusComponent success={remoteproductSuccess} error={remoteProductError} />
                </FormRow>
            )}
        </>
    );
}
