import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import './styles/style.scss';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Login } from './Login';
import { ReactNode, useEffect } from 'react';

export const cgTokenKey = 'c_cg_auth_token';
export const cgUserKey = 'c_cg_auth_user';
export const cgIdKey = 'c_cg_auth_id';
export const cgDescKey = 'c_cg_auth_desc';

function RouterRedirect() {
    const [cookies] = useCookies([cgTokenKey]);
    return <Redirect to={cookies?.[cgTokenKey] ? '/dashboard' : '/login'} />;
}

function AuthCore(props: { children: ReactNode }) {
    const { children } = props;
    const location = useLocation();
    const history = useHistory();
    const [cookies] = useCookies([cgTokenKey]);

    useEffect(() => {
        if (location.pathname?.includes('/login') && (global.cgTokenKey || cookies?.[cgTokenKey])) {
            history.push(`/dashboard`);
            return;
        }
        if (location.pathname?.includes('/dashboard') && !global.cgTokenKey && !cookies?.[cgTokenKey]) {
            history.push(`/login`);
            return;
        }
    }, [location, history, cookies]);

    return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <ThemeProvider theme={theme}>
            <AuthCore>
                <Switch>
                    <Route exact path={'/'}>
                        <RouterRedirect />
                    </Route>
                    <Route exact path={'/dashboard'} component={App} />
                    <Route exact path={'/login'} component={Login} />
                    <Route path="*">
                        <Redirect to="/login" />
                    </Route>
                </Switch>
            </AuthCore>
        </ThemeProvider>
    </Router>
);
