import styled from 'styled-components';
import { useEffect, useState, useMemo, ChangeEvent, FocusEvent } from 'react';
import { Label } from './Label';
import { FormError } from './FormError';
import { StyleRecord } from '../types/UtilTypes';
import { InputHeight } from './FormTypes';
import { v4 as uuid } from 'uuid';

const InputContainer = styled.div`
    width: 100%;
    position: relative;
    transition: var(--transTime) opacity linear !important;
`;

const StyledInput = styled.input<{ customInputHeight?: string; hasError: boolean }>`
    width: 100%;
    height: ${({ customInputHeight }) => customInputHeight ?? InputHeight};
    padding: 0.7rem 1.5rem;

    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.PINK_FG : theme.INPUT_BORDER)};
    background: ${({ theme }) => theme.INPUT_BG};
    outline: none;

    color: ${({ theme }) => theme.INPUT_FG};
    font-size: 1.2rem;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &::placeholder {
        color: ${({ theme }) => theme.INPUT_PLACEHOLDER};
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.INPUT_BORDER_ACTIVE};
    }
`;

interface InputProps {
    value: string | number;
    onChange: (value: string, e?: ChangeEvent<HTMLInputElement>) => void;
    id?: string;
    type?: string;
    placeholder?: string;
    style?: StyleRecord;
    styleInput?: StyleRecord;
    className?: string;
    disabled?: boolean;
    error?: string;
    label?: string;
    labelOptional?: string;
    onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
    onChangeMap?: (value: string) => string;
    customInputHeight?: string;
}

export function Input(props: InputProps) {
    const {
        id: idProp,
        type,
        placeholder,
        style,
        styleInput,
        value,
        className,
        disabled: disabledProp,
        error,
        label,
        labelOptional,
        onFocus,
        onBlur,
        onChange,
        onChangeMap: onChangeMapProp,
        customInputHeight,
    } = props;

    const [internalValue, setInternalValue] = useState(value ?? '');

    // NOTE: Core Memo Triggers
    const ID = useMemo(() => {
        if (idProp) return idProp;
        return uuid();
    }, [idProp]);

    const DISABLED = useMemo(() => {
        const isDisabled = typeof disabledProp === 'boolean' && disabledProp;
        return isDisabled;
    }, [disabledProp]);

    const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        onFocus?.(e);
    };

    const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        onBlur?.(e);
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const mappedValue = onChangeMapProp ? onChangeMapProp(e.target.value) : e.target.value;
        setInternalValue(mappedValue);
        onChange?.(mappedValue, e);
    };

    useEffect(() => {
        if (value !== internalValue) setInternalValue(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <InputContainer style={style && style} className={className && className}>
            <Label label={label} id={ID} labelOptional={labelOptional} disabled={DISABLED} />
            <StyledInput
                customInputHeight={customInputHeight}
                disabled={DISABLED}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onChange={onInputChange}
                value={internalValue}
                type={type}
                name={`${ID}_name`}
                id={ID}
                placeholder={placeholder}
                style={styleInput && styleInput}
                hasError={error && DISABLED !== true}
            />
            {error && DISABLED !== true && <FormError>{error}</FormError>}
        </InputContainer>
    );
}
