import { useCallback, useMemo, useState } from 'react';

export function useSendMessage() {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string | undefined>(undefined);

    const onClickSendMessage = useCallback(
        async (title: string, message: string) => {
            try {
                if (loading) return;
                setLoading(true);
                setSuccess(undefined);
                await new Promise((resolve) => setTimeout(resolve, 300));
                const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/cg/message`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        title: title?.trim(),
                        message: message?.trim(),
                    }),
                });
                const body = (await res.json()) as { success: boolean; error?: string };
                if (!body?.success) {
                    setError(new Error(body?.error));
                } else {
                    setSuccess('Message send!');
                }
            } catch (err) {
                setError(new Error(`Failed to send message: ${err?.message}`));
            } finally {
                setLoading(false);
            }
        },
        [loading, history]
    );

    return useMemo(
        () => ({
            onClickSendMessage,
            loading,
            error,
            success,
        }),
        [onClickSendMessage, loading, error, success]
    );
}
