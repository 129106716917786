import { useCallback, useEffect, useMemo, useState } from 'react';
import { CookGroupProduct } from '../types/CookGroupTypes';

export function useProducts() {
    const [products, setProducts] = useState<CookGroupProduct[] | undefined>(undefined);
    const [productsError, setError] = useState<Error | undefined>(undefined);
    const [productsLoading, setLoading] = useState<boolean>(false);
    const [productsSuccess, setSuccess] = useState<string>(undefined);

    const fetchProducts = useCallback(async () => {
        try {
            if (productsLoading) return;
            setLoading(true);
            setSuccess(undefined);
            const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/cg/products`, {
                method: 'GET',
                credentials: 'include',
                headers: {},
            });
            const body = (await res.json()) as { success: boolean; data?: CookGroupProduct[]; error?: string };
            if (!body?.success) {
                setError(new Error(body?.error));
            } else {
                setProducts(body?.data);
            }
        } catch (err) {
            setError(new Error(`Failed to retrieve products: ${err?.message}`));
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, []);

    return useMemo(
        () => ({
            productsLoading,
            productsError,
            productsSuccess,
            products,
        }),
        [productsLoading, productsError, productsSuccess, products]
    );
}
