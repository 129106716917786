import { useCallback, useMemo, useState } from 'react';
import { CookGroupProduct } from '../types/CookGroupTypes';

export function useRemoteProduct() {
    const [remoteProductResult, setRemoteProductResult] = useState<{ success: number; failed: number } | undefined>(undefined);
    const [remoteProductError, setRemoteProductError] = useState<Error | undefined>(undefined);
    const [remoteProductLoading, setRemoteProductLoading] = useState<boolean>(false);
    const [remoteproductSuccess, setRemoteproductSuccess] = useState<string>(undefined);

    const onClickStartProduct = useCallback(
        async (product: CookGroupProduct) => {
            try {
                if (remoteProductLoading) return;
                setRemoteProductLoading(true);
                setRemoteproductSuccess(undefined);
                setRemoteProductError(undefined);
                const res = await fetch(`https://qt.caspianaio.com/cg/product/start`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        product: product,
                    }),
                });
                const body = (await res.json()) as { success: boolean; error?: string; data?: { success: number; failed: number } };
                if (!body?.success) {
                    setRemoteProductError(new Error(body?.error));
                } else {
                    setRemoteProductResult(body?.data);
                    setRemoteproductSuccess(`"${product?.name}" started!`);
                }
            } catch (err) {
                setRemoteProductError(new Error(`Failed to start product: ${err?.message}`));
            } finally {
                setRemoteProductLoading(false);
            }
        },
        [remoteProductLoading]
    );

    const onClickStopProduct = useCallback(
        async (product: CookGroupProduct) => {
            try {
                if (remoteProductLoading) return;
                setRemoteProductLoading(true);
                setRemoteproductSuccess(undefined);
                setRemoteProductError(undefined);
                const res = await fetch(`https://qt.caspianaio.com/cg/product/stop`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        product: product,
                    }),
                });
                const body = (await res.json()) as { success: boolean; error?: string; data?: { success: number; failed: number } };
                if (!body?.success) {
                    setRemoteProductError(new Error(body?.error));
                } else {
                    setRemoteProductResult(body?.data);
                    setRemoteproductSuccess(`"${product?.name}" started!`);
                }
            } catch (err) {
                setRemoteProductError(new Error(`Failed to stop product: ${err?.message}`));
            } finally {
                setRemoteProductLoading(false);
            }
        },
        [remoteProductLoading]
    );

    return useMemo(
        () => ({
            onClickStartProduct,
            onClickStopProduct,
            remoteProductLoading,
            remoteProductError,
            remoteproductSuccess,
            remoteProductResult,
        }),
        [onClickStartProduct, onClickStopProduct, remoteProductLoading, remoteProductError, remoteproductSuccess, remoteProductResult]
    );
}
