import styled from 'styled-components';
import { useMemo } from 'react';
import { Label } from './Label';
import { v4 as uuid } from 'uuid';
import { StyleRecord } from '../types/UtilTypes';
import { FlexCol } from '../components/Flex';

const StyledSwitch = styled.div<{ disabled: boolean }>`
    position: relative;
    width: auto;
    height: 2.7rem;
    display: flex;
    align-items: center;
    label {
        cursor: pointer;
        margin-bottom: 0 !important;
    }
    ${({ disabled }) => disabled && 'opacity: 0.4;'}
`;

const StyledSwitchContainer = styled.div<{ disabled: boolean; active: boolean }>`
    width: 4.8rem;
    height: 2.7rem;
    padding: 0.5rem 0.6rem;
    display: flex;
    border-radius: 12px;
    flex-shrink: 0;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    background-color: ${({ active, theme }) => (active ? theme.SWITCH_TOGGLE_BG_ACTIVE : theme.SWITCH_TOGGLE_BG)};
    border: 1px solid ${({ active, theme }) => (active ? theme.INPUT_BORDER_ACTIVE : theme.INPUT_BORDER)};
    transition: 0.3s all;
`;

const StyledToggleSwitch = styled.div<{ active: boolean }>`
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: ${({ active, theme }) => (active !== true ? theme.SWITCH_TOGGLE_FG : theme.SWITCH_TOGGLE_FG_ACTIVE)};
    transform: translateX(${({ active }) => (active === null ? '0.9rem' : active ? '2rem' : '0px')});
    transition: 0.3s all !important;
`;

interface SwitchProps {
    value: boolean | null;
    onChange: (value: boolean | null) => void;
    style?: StyleRecord;
    styleSwitch?: StyleRecord;
    styleLabel?: StyleRecord;
    disabled?: boolean;
    label?: string | JSX.Element;
    labelOptional?: string | JSX.Element;
    id?: string;
    reverse?: boolean;
    error?: string;
    onFocus?: () => void;
}

export function Switch(props: SwitchProps) {
    const { onChange, value, reverse, style, styleSwitch, styleLabel, disabled: disabledProp, label, labelOptional, id: idProp } = props;

    const ID = useMemo(() => {
        if (idProp) return idProp;
        return uuid();
    }, [idProp]);

    const DISABLED = useMemo(() => {
        const isDisabled = typeof disabledProp === 'boolean' && disabledProp;
        return isDisabled;
    }, [disabledProp]);

    const toggleActive = () => {
        if (!DISABLED) {
            onChange(value === null ? true : !value);
        }
    };

    const switchContainer = (
        <StyledSwitchContainer active={value === true} onClick={() => toggleActive()} disabled={DISABLED} style={styleSwitch && styleSwitch}>
            <StyledToggleSwitch active={value === true} />
        </StyledSwitchContainer>
    );

    const labelContainer = label ? (
        <FlexCol>
            <Label
                onClick={() => toggleActive()}
                label={label}
                id={ID}
                labelOptional={labelOptional}
                style={{ marginLeft: reverse ? 10 : 0, marginRight: reverse ? 0 : 10, ...styleLabel }}
                disabled={DISABLED}
            />
            {value === null && (
                <Label
                    onClick={() => toggleActive()}
                    id={ID}
                    labelOptional="Various Values"
                    style={{ marginLeft: reverse ? 10 : -5, marginRight: reverse ? -5 : 10, ...styleLabel }}
                    disabled={DISABLED}
                />
            )}
        </FlexCol>
    ) : null;

    return (
        <StyledSwitch id={ID} style={style && style} disabled={DISABLED}>
            {reverse ? (
                <>
                    {switchContainer} {labelContainer}
                </>
            ) : (
                <>
                    {labelContainer} {switchContainer}
                </>
            )}
        </StyledSwitch>
    );
}
