import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyledPage } from './components/Page';
import { ReactComponent as CaspianLogo } from './assets/caspian_qt_logo.svg';
import styled from 'styled-components';
import { FlexCol } from './components/Flex';
import { Input } from './form/Input';
import { FormRow } from './form/FormRow';
import { ButtonColor, ButtonColorTypes } from './components/Button';
import { Text } from './components/Text';
import { useLogin } from './hooks/useLogin';

const StyledContent = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: clamp(20px, 10vw, 100px) 20px;
`;

const StyledIcon = styled.div`
    height: 16vw;
    max-height: 120px;
    display: flex;
    justify-content: center;
    svg {
        height: 100%;
        width: auto;
    }
`;

export function Login() {
    const { loading, onClickLogin, error } = useLogin();

    const [cookGroupId, setCookGroupId] = useState<string>('');
    const [cookGroupPass, setCookGroupPass] = useState<string>('');

    return (
        <StyledPage>
            <StyledContent>
                <StyledIcon>
                    <CaspianLogo />
                </StyledIcon>
                <FlexCol>
                    <FormRow columns="1fr">
                        <Input label="CookGroup ID" value={cookGroupId} onChange={setCookGroupId} placeholder="CaspianAIO" />
                    </FormRow>
                    <FormRow columns="1fr">
                        <Input label="CookGroup Pass" value={cookGroupPass} onChange={setCookGroupPass} placeholder="Chef123" type="password" />
                    </FormRow>
                    <FormRow columns="1fr">
                        <ButtonColor color={ButtonColorTypes.DEFAULT} onClick={() => onClickLogin(cookGroupId, cookGroupPass)} disabled={loading}>
                            Login
                        </ButtonColor>
                    </FormRow>
                    {error instanceof Error && (
                        <Text color="PINK_FG" size="min(1.8vh, 2rem)" weight="600" multiLiner={true} style={{ textAlign: 'center', marginTop: 20 }}>
                            {error?.message}
                        </Text>
                    )}
                </FlexCol>
            </StyledContent>
        </StyledPage>
    );
}
