import styled from 'styled-components';

const StyledFormError = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.FONTS_PRIMARY};
    color: ${({ theme }) => theme.PINK_FG};
    white-space: pre-wrap;
`;

interface FormErrorProps {
    children: string | JSX.Element;
}

export const FormError = (props: FormErrorProps) => {
    return <StyledFormError>{props.children}</StyledFormError>;
};
