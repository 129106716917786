export const VARIOUS_VALUES_KEY = '[Various values]';

export const InputHeight = '3rem';

export interface SelectOption {
    value: string;
    text: string;
    hidden?: boolean;
    altValue?: string;
}

export interface SelectOptionGroup {
    text: string;
    value: string;
    options: SelectOption[];
}

export interface DateInputValue {
    year: number;
    month: number;
    date: number;
}
