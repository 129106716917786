import { SelectOption } from '../form/FormTypes';
import { convertEnumsToOptions } from '../scripts/scripts_types';

export enum SiteTypes {
    MICROSOFT = 'https://www.microsoft.com',
    WALMART = 'https://www.walmart.com',
    TARGET = 'https://www.target.com',
    NBB = 'https://www.notebooksbilliger.de',
    GAMESTOP_CA = 'http://www.gamestop.ca',
    CORSAIR = 'https://www.corsair.com',
    COSTCO = 'https://costco.com',
    CONVERSE = 'https://www.converse.com',
    AMAZON = 'https://www.amazon.com',
    LDLC = 'https://www.ldlc.com',
    KITH = 'https://eu.kith.com',
    LVR = 'https://www.luisaviaroma.com',
    WALMART_CA = 'https://www.walmart.ca',
    FOOTLOCKER_CA = 'https://www.footlocker.ca',
    FOOTLOCKER = 'https://www.footlocker.com',
    FOOTLOCKER_CA_RAFFLE = 'https://www.footlocker.ca/raffle',
    FOOTLOCKER_RAFFLE = 'https://www.footlocker.com/raffle',
    EASTBAY = 'https://www.eastbay.com',
    CHAMPS_SPORTS = 'https://www.champssports.com',
    CHAMPS_SPORTS_CA = 'https://www.champssports.ca',
    POKEMON_CENTER = 'https://www.pokemoncenter.com',
    FOOTLOCKER_KIDS = 'https://www.kidsfootlocker.com',
    AMD = 'https://www.amd.com/en/direct-buy/us',
    BESTBUY_US = 'https://www.bestbuy.com',
    BURBERRY = 'https://www.burberry.com',
    SWATCH = 'https://www.swatch.com',
    LEGO = 'https://www.lego.com',
    FANATICS = 'https://www.fanatics.com',
    JD_SPORTS = 'https://www.jdsports.co.uk',
    SIZE = 'https://www.size.co.uk',
    FOOTPATROL = 'https://www.footpatrol.com',
    HIPSTORE = 'https://www.thehipstore.co.uk',
    PLAYSTATION = 'https://playstation.com',
    TOPPS = 'https://www.topps.com',
    OFF_WHITE = 'https://drops.off---white.com',
    COURIR = 'https://www.courir.com',
    TRAVIS_SCOTT = 'https://shop.travisscott.com',
    ABOUTYOU = 'https://www.aboutyou.com',
    PANINI = 'https://www.paninistore.com',
    QUEUE_IT = 'https://queue-it.com',
    QUEUE_IT_NEW = 'https://queue-it.com/new',
    ABC_MART = 'https://www.abc-mart.net',
    LOUIS_VUITTON = 'https://www.louisvuitton.com',
    ZOZOTOWN = 'https://zozo.jp',
    RAKUTEN = 'https://www.rakuten.co.jp',
    SACAI = 'https://drops.sacai.jp',
    NIKE = 'https://www.nike.com',
    APPLE = 'https://www.apple.com',
    MYNTRA = 'https://www.myntra.com',
    VEGNONVEG = 'https://www.vegnonveg.com',
    WTAPS = 'https://www.wtaps.com',
    TAF = 'https://www.taf.com.mx',
    END = 'https://www.endclothing.com',
    BASE = 'https://thebase.in',
    ASOS = 'https://www.asos.com',
    KICKZ = 'https://www.kickz.com',
    TESSUTI = 'https://www.tessuti.co.uk',
    WELLGOSH = 'https://www.wellgosh.com',
    SNIPES = 'https://www.snipes.com',
    ONYGO = 'https://www.onygo.com',
    SOLEBOX = 'https://www.solebox.com',
    OFFICE = 'https://www.office.co.uk',
    BSTN = 'https://www.bstn.com',
    OFFSPRING = 'https://www.offspring.co.uk',
    WONDERLAND = 'https://www.wonderland.com',
    AMBUSH = 'https://drops.ambushdesign.com',
    FOOTSHOP = 'https://footshop.com',
    SNKRS = 'https://www.nike.com/launch',
    BILLYS_TOKYO = 'https://www.billys-tokyo.net',
    POKEMON_CENTER_JP = 'https://www.pokemoncenter-online.com',
    STORES_JP = 'https://stores.jp/',
    CASIO = 'https://www.casio.com',
    OVERKILL = 'https://www.overkillshop.com',
    OPIUM_PARIS = 'https://www.opiumparis.com',
    SVD = 'https://www.sivasdescalzo.com',
    ADIDAS = 'https://www.adidas.com',
}

export enum SiteNames {
    MICROSOFT = 'Microsoft',
    WALMART = 'Walmart',
    TARGET = 'Target',
    NBB = 'Notebooks Billiger',
    GAMESTOP_CA = 'Gamestop CA',
    CORSAIR = 'Corsair',
    COSTCO = 'Costco',
    CONVERSE = 'Converse',
    AMAZON = 'Amazon',
    LDLC = 'LDLC',
    KITH = 'Kith International',
    LVR = 'Luisa Via Roma',
    WALMART_CA = 'Walmart CA',
    FOOTLOCKER_CA = 'Footlocker CA',
    FOOTLOCKER = 'Footlocker US',
    FOOTLOCKER_CA_RAFFLE = 'Footlocker Raffle',
    FOOTLOCKER_RAFFLE = 'Footlocker Raffle CA',
    EASTBAY = 'Eastbay',
    CHAMPS_SPORTS = 'Champs Sports',
    CHAMPS_SPORTS_CA = 'Champs Sports CS',
    POKEMON_CENTER = 'Pokemon Center',
    FOOTLOCKER_KIDS = 'Footlocker Kids',
    AMD = 'AMD',
    BESTBUY_US = 'Bestbuy',
    BURBERRY = 'Burberry',
    SWATCH = 'Swatch',
    LEGO = 'Lego',
    FANATICS = 'Fanatics',
    JD_SPORTS = 'JD Sports',
    SIZE = 'Size?',
    FOOTPATROL = 'Footpatrol',
    HIPSTORE = 'The Hipstore',
    PLAYSTATION = 'PlayStation Direct',
    TOPPS = 'Topps',
    OFF_WHITE = 'Off-White',
    COURIR = 'Courir',
    TRAVIS_SCOTT = 'Travis Scott',
    ABOUTYOU = 'AboutYou',
    PANINI = 'Panini',
    QUEUE_IT = 'Queue-IT',
    QUEUE_IT_NEW = 'Queue-IT New',
    ABC_MART = 'ABC-Mart',
    LOUIS_VUITTON = 'Louis Vuitton',
    ZOZOTOWN = 'ZOZOTOWN',
    RAKUTEN = 'Rakuten',
    SACAI = 'Sacai',
    NIKE = 'Nike',
    APPLE = 'Apple',
    MYNTRA = 'Myntra',
    VEGNONVEG = 'VegNonVeg',
    WTAPS = 'WTAPS',
    TAF = 'TAF',
    END = 'END Clothing',
    BASE = 'Base',
    ASOS = 'ASOS',
    KICKZ = 'Kickz',
    TESSUTI = 'Tessuti',
    WELLGOSH = 'Wellgosh',
    SNIPES = 'Snipes',
    ONYGO = 'Onygo',
    SOLEBOX = 'Solebox',
    OFFICE = 'Office',
    BSTN = 'BSTN',
    OFFSPRING = 'Offspring',
    WONDERLAND = 'Wonderland',
    AMBUSH = 'Ambush',
    FOOTSHOP = 'Footshop',
    SNKRS = 'SNKRS',
    BILLYS_TOKYO = 'Billys Tokyo',
    POKEMON_CENTER_JP = 'Pokemon Center JP',
    STORES_JP = 'Stores',
    CASIO = 'Casio',
    OVERKILL = 'Overkill',
    OPIUM_PARIS = 'Opium Paris',
    SVD = 'SVD',
    ADIDAS = 'Adidas',
}

export const SiteEnumOptions = convertEnumsToOptions(SiteTypes, SiteNames);
