import styled from 'styled-components';
import { MouseEvent } from 'react';
import { StyleRecord } from '../types/UtilTypes';
import { FlexRowMiddle } from '../components/Flex';

const StyledLabel = styled.label<{ disabled: boolean }>`
    height: 1.6rem;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    color: ${({ theme }) => theme.INPUT_LABEL};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    span {
        margin-left: 5px;
        color: ${({ theme }) => theme.INPUT_LABEL_OPTIONAL};
    }
`;

interface LabelProps {
    id?: string;
    label?: string | JSX.Element;
    labelOptional?: string | JSX.Element;
    style?: StyleRecord;
    onClick?: (e: MouseEvent<HTMLLabelElement>) => void;
    disabled: boolean;
}

export const Label = (props: LabelProps) => {
    const { id, label, labelOptional, style, disabled, onClick } = props;
    if ((label || labelOptional) && id)
        return (
            <FlexRowMiddle>
                <StyledLabel htmlFor={id} style={style && style} onClick={(e) => onClick?.(e)} disabled={disabled}>
                    {label ? label : ''}
                    {labelOptional && <span>{labelOptional}</span>}
                </StyledLabel>
            </FlexRowMiddle>
        );
    else return null;
};
