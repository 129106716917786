import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormRow } from './form/FormRow';
import { ButtonColor, ButtonColorTypes } from './components/Button';
import { StatusComponent } from './components/StatusComponent';
import { useSendMessage } from './hooks/useSendMessage';
import { Input } from './form/Input';
import { Textarea } from './form/Textarea';

export function Announcements() {
    const { loading, success, onClickSendMessage, error } = useSendMessage();

    const [webhookURL, setWebhookURL] = useState<string>('');
    const [cookGroupMessage, setCookGroupMessage] = useState<string>('');
    const [cookGroupMessageTitle, setCookGroupMessageTitle] = useState<string>('');

    useEffect(() => {
        if (success?.trim()?.length) {
            setCookGroupMessage('');
            setCookGroupMessageTitle('');
        }
    }, [success]);

    return (
        <>
            <FormRow columns="1fr">
                <Input label="Webhook URL" value={webhookURL} onChange={setWebhookURL} placeholder="https://discord.com/api/webhooks/...." disabled={true} />
            </FormRow>
            <FormRow columns="1fr">
                <Input label="Title" labelOptional="(Optional)" value={cookGroupMessageTitle} onChange={setCookGroupMessageTitle} placeholder="Yeezy Drop..." />
            </FormRow>
            <FormRow columns="1fr">
                <Textarea
                    label="Message"
                    value={cookGroupMessage}
                    onChange={setCookGroupMessage}
                    placeholder="Please write your message here..."
                    style={{ height: 'clamp(200px, 40vh, 600px)' }}
                />
            </FormRow>

            <FormRow columns="1fr">
                <ButtonColor
                    color={ButtonColorTypes.DEFAULT}
                    onClick={() => onClickSendMessage(cookGroupMessageTitle, cookGroupMessage)}
                    disabled={loading || !cookGroupMessage?.trim()?.length}
                >
                    Send
                </ButtonColor>
            </FormRow>
            <StatusComponent success={success} error={error} />
        </>
    );
}
