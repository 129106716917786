import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export function useLogin() {
    const history = useHistory();
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickLogin = useCallback(
        async (cookGroupId: string, cookGroupPass: string) => {
            try {
                if (loading) return;
                setLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 300));
                const res = await fetch(`${process.env.NODE_ENV !== 'production' ? 'http://localhost:5204' : ''}/api/auth/login`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: cookGroupId,
                        pass: cookGroupPass,
                    }),
                });
                const body = (await res.json()) as { success: boolean; error?: string };
                if (!body?.success) {
                    setError(new Error(body?.error));
                } else {
                    history.push('/dashboard');
                    global.cgTokenKey = document.cookie?.split('c_cg_auth_token=')[1]?.split(';')[0];
                }
            } catch (err) {
                setError(new Error(`Failed to login: ${err?.message}`));
            } finally {
                setLoading(false);
            }
        },
        [loading, history]
    );

    return useMemo(
        () => ({
            onClickLogin,
            loading,
            error,
        }),
        [onClickLogin, loading, error]
    );
}
