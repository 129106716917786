import styled from 'styled-components';

export const GridCol = styled.div`
    display: grid;
    grid-template-columns: auto;
`;

export const GridRow = styled.div`
    display: grid;
    grid-template-rows: auto;
`;
