import { MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyleRecord } from '../types/UtilTypes';

const StyledText = styled.p<{ color?: string; size?: string; weight?: string; font?: string; colorHover?: string; glow?: boolean; multiLiner?: boolean }>`
    color: ${(props) => (props.color ? props.theme[props.color] : props.theme.MAIN_FG)};
    font-size: ${(props) => (props.size ? props.size : 'initial')};
    font-weight: ${(props) => (props.weight ? props.weight : '600')};
    font-family: ${(props) => (props.font ? props.theme[props.font] : props.theme.FONTS_PRIMARY)};
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ multiLiner }) =>
        multiLiner
            ? `
            white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    `
            : 'white-space: nowrap;'}
    ${({ colorHover }) => (colorHover ? `transition: 0.2s!important;` : '')}
    ${({ theme, glow }) => (glow ? theme.TEXT_GLOW_ALT : '')}
    &:hover {
        ${({ colorHover, theme }) => (colorHover ? `color: ${theme[colorHover]};` : '')}
    }
`;

interface TextProps {
    font?: string;
    style?: StyleRecord;
    color?: string;
    colorHover?: string;
    weight?: string;
    size?: string;
    onClick?: (e: MouseEvent) => void;
    multiLiner?: boolean;
    children: ReactNode;
}
export function Text(props: TextProps) {
    const { color, colorHover, weight, size, style, children, font, multiLiner } = props;
    return (
        <StyledText
            multiLiner={multiLiner && multiLiner}
            font={font}
            style={style && style}
            color={color && color}
            colorHover={colorHover && colorHover}
            weight={weight}
            size={size}
        >
            {children}
        </StyledText>
    );
}
