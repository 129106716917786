import styled from 'styled-components';
import { StyleRecord } from '../types/UtilTypes';

const StyledLoadingDotContainer = styled.div<{ dotColor: string }>`
    margin: 20px auto;
    display: block;
    position: relative;
    width: 75px;
    height: 16px;
    div {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${({ theme, dotColor }) => theme[dotColor]};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        &:nth-child(1) {
            left: 0px;
            animation: lds-ellipsis1 0.8s infinite;
        }
        &:nth-child(2) {
            left: 0px;
            animation: lds-ellipsis2 0.8s infinite;
        }
        &:nth-child(3) {
            left: 30px;
            animation: lds-ellipsis2 0.8s infinite;
        }
        &:nth-child(4) {
            left: 59px;
            animation: lds-ellipsis3 0.8s infinite;
        }
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(29px, 0);
        }
    }
`;

export function LoadingDots(props: { dotColor: string; style?: StyleRecord }) {
    const { style, dotColor } = props;

    return (
        <StyledLoadingDotContainer style={style && style} dotColor={dotColor}>
            <div />
            <div />
            <div />
            <div />
        </StyledLoadingDotContainer>
    );
}
