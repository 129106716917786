import { useMemo } from 'react';
import styled from 'styled-components';
import { Label } from './Label';
import { FormError } from './FormError';
import { StyleRecord } from '../types/UtilTypes';
import { LabelToggle } from './FormTypes';
import { v4 as uuid } from 'uuid';

const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const StyledTextarea = styled.textarea`
    width: 100% !important;
    flex: 1;
    padding: 1rem 1.5rem;

    border: 1px solid ${({ theme }) => theme.INPUT_BORDER};
    background: ${({ theme }) => theme.INPUT_BG};
    outline: none;
    resize: none;

    color: ${({ theme }) => theme.INPUT_FG};
    font-size: 1.3rem;
    font-weight: 600;

    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow-x: hidden;
    overflow-y: overlay;
    text-overflow: ellipsis;

    &::placeholder {
        color: ${({ theme }) => theme.INPUT_PLACEHOLDER};
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.INPUT_BORDER_ACTIVE};
    }
`;

interface TextareaProps {
    value: string;
    id?: string;
    placeholder?: string;
    style?: StyleRecord;
    disabled?: boolean;
    error?: string;
    label?: string;
    labelOptional?: string;
    onFocus?: () => void;
    onChange: (value: string) => void;
}

export function Textarea(props: TextareaProps) {
    const { value, id: idProp, placeholder, style, labelOptional, error, disabled: disabledProp, label, onFocus, onChange } = props;

    const ID = useMemo(() => {
        if (idProp) return idProp;
        return uuid();
    }, [idProp]);

    const DISABLED = useMemo(() => {
        const isDisabled = typeof disabledProp === 'boolean' && disabledProp;
        return isDisabled;
    }, [disabledProp]);

    return (
        <InputContainer>
            <Label label={label} id={ID} labelOptional={labelOptional} disabled={DISABLED} />
            {error && <FormError>{error}</FormError>}
            <StyledTextarea
                style={style && style}
                disabled={DISABLED}
                onFocus={() => onFocus?.()}
                onChange={(e) => onChange?.(e.target.value)}
                name={`${ID}_name`}
                id={ID}
                placeholder={placeholder}
                value={value}
                wrap="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
            />
        </InputContainer>
    );
}
