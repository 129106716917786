import styled from 'styled-components';
import { FlexRow } from './Flex';

const StyledNavContainer = styled(FlexRow)`
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;
`;

const StyledNavButton = styled.button<{ selected?: boolean }>`
    width: 100%;
    padding: 10px 0 !important;
    outline: none;
    border: none;
    background: ${({ theme, selected }) => (selected ? theme.NAV_BUTTON_BG_ACTIVE : theme.NAV_BUTTON_BG)};
    border-bottom: 2px solid ${({ theme, selected }) => (selected ? theme.NAV_BUTTON_BORDER_ACTIVE : theme.NAV_BUTTON_BORDER)};
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    white-space: nowrap;
    color: #fafafa;
`;

export enum PageTypes {
    ANNOUNCEMENTS,
    PRODUCT_MANAGEMENT,
    PRODUCT_REMOTE,
}

export function Nav(props: { selectedPage: PageTypes; setPage: (v: PageTypes) => void }) {
    const { selectedPage, setPage } = props;

    return (
        <StyledNavContainer>
            <StyledNavButton selected={selectedPage === PageTypes.ANNOUNCEMENTS} onClick={() => setPage(PageTypes.ANNOUNCEMENTS)}>
                Announcements
            </StyledNavButton>
            <StyledNavButton selected={selectedPage === PageTypes.PRODUCT_MANAGEMENT} onClick={() => setPage(PageTypes.PRODUCT_MANAGEMENT)}>
                Product Management
            </StyledNavButton>
            <StyledNavButton selected={selectedPage === PageTypes.PRODUCT_REMOTE} onClick={() => setPage(PageTypes.PRODUCT_REMOTE)}>
                Product Remote
            </StyledNavButton>
        </StyledNavContainer>
    );
}
