import _ from 'lodash';
import styled from 'styled-components';
import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { FormError } from './FormError';
import { Label } from './Label';
import { InputHeight, SelectOption, VARIOUS_VALUES_KEY } from './FormTypes';
import { v4 as uuid } from 'uuid';
import { StyleRecord } from '../types/UtilTypes';
import { getOffset } from '../scripts/scripts_dom';
import { Text } from '../components/Text';
import { InfiniteListItem, InfiniteListItemAlt } from '../components/InfiniteList';
import { ContentList, ContentListList } from '../components/Components';

type ActiveProps = { active?: boolean };

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SelectMultiContainer = styled.div<ActiveProps & { disabled: boolean }>`
    position: relative;
    ${({ disabled }) => disabled && 'opacity: 0.4;'}
    :after {
        width: 100%;
        clear: both;
        content: '';
        display: block;
    }
`;

const InputBox = styled.div<{ disabled?: boolean; active?: boolean }>`
    width: 100%;
    height: ${InputHeight};
    padding: 0.6rem 3rem 0.6rem 1.5rem;

    display: flex;
    align-items: center;

    visibility: ${({ active }) => (active ? 'hidden' : 'visible')};

    border: 1px solid ${({ active, theme }) => (active ? theme.INPUT_BORDER_ACTIVE : theme.INPUT_BORDER)};
    background: ${({ theme }) => theme.INPUT_BG};

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    position: relative;
    * {
        ${({ disabled }) => (disabled ? 'cursor: not-allowed!important;' : '')};
    }

    ::after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 2px solid ${({ theme }) => theme.INPUT_FG};
        border-bottom: 2px solid ${({ theme }) => theme.INPUT_FG};

        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 8px;
        right: 13px;

        position: absolute;
        pointer-events: none;
    }
`;

const SelectDropdown = styled.div<{ disabled?: boolean; active?: boolean }>`
    visibility: ${(props) => (props.active === true ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.active === true ? '1' : '0')};
    display: flex;
    flex-direction: column;
    position: fixed;

    border: 1px solid ${(props) => props.theme.INPUT_BORDER_ACTIVE};
    background: ${({ theme }) => theme.INPUT_BG};

    z-index: ${({ active }) => (active ? 10 : 0)};
    ${({ active, disabled, theme }) => active && !disabled && `box-shadow: 0 0 10px 8px ${theme.SELECT_BOX_SHADOW}`}
`;

const SearchBox = styled.input<{ customInputHeight?: string }>`
    width: 100%;
    height: ${({ customInputHeight }) => customInputHeight ?? InputHeight};
    padding: 0.7rem 3rem 0.7rem 1.5rem;

    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid transparent;

    // Cancel out input styling
    outline: 0;
    background-color: transparent;
    border: none;

    // Input text
    color: ${(props) => props.theme.INPUT_FG};
    font-size: 1.2rem;
    font-weight: 600;

    &::placeholder {
        color: ${(props) => props.theme.INPUT_PLACEHOLDER};
    }

    ::after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 2px solid ${(props) => props.theme.INPUT_FG};
        border-bottom: 2px solid ${(props) => props.theme.INPUT_FG};

        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
        right: 13px;

        position: absolute;
        pointer-events: none;
    }
`;

const OptionsBox = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const OptionsList = styled.div`
    width: 100%;
    padding: 5px 0px 5px 10px;
`;

const OptionBox = styled.div<ActiveProps>`
    width: calc(100% - 19px) !important;
    height: ${InputHeight} !important;
    margin-bottom: 5px;
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${({ active }) => (active ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0)')};
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    &::after {
        content: '';
        width: 8px;
        height: 8px;
        margin: auto 0 auto auto;
        display: block;
        border-radius: 50%;
        background-color: ${({ active, theme }) => (active ? theme.MAIN_FG_THEME : 'transparent')};
    }
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const OptionsBoxDivider = styled.div`
    display: block;
    height: 1px;
    width: calc(100% - 30px);
    margin-left: 15px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.INPUT_BORDER};
`;

// TODO: Type guard.
const getInitialState = (valueProp: string | string[], multiProp: boolean | undefined) => {
    if (multiProp) return valueProp?.length > 0 ? _.cloneDeep(valueProp) : [''];
    else return valueProp ? _.cloneDeep(valueProp) : '';
};

export interface BaseSelectProps {
    options: SelectOption[];
    disabled?: boolean;
    label?: string;
    id?: string;
    error?: string;
    labelOptional?: string;
    className?: string;
    style?: StyleRecord;
    onFocus?: () => void;
    cancelError?: () => void;
}

export interface SingleValueSelectProps extends BaseSelectProps {
    value: string;
    multi?: false;
    onChange: (value: string) => void;
}

export interface MultipleValueSelectProps extends BaseSelectProps {
    value: string[];
    multi: boolean;
    onChange: (value: string[]) => void;
}

export function Select(props: SingleValueSelectProps | MultipleValueSelectProps) {
    // Props
    const {
        disabled: disabledProp,
        multi: multiProp,
        onChange: onChangeProp,
        onFocus: onFocusProp,
        value,
        error,
        options,
        style,
        id: idProp,
        label,
        labelOptional,
    } = props;

    // State
    const [selectActive, setSelectActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selection, setSelection] = useState(getInitialState(value, multiProp));

    // References
    const containerRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchBoxRef = useRef<HTMLInputElement>(null);
    const selectTopDivRef = useRef<HTMLDivElement>(null);
    const selectBottomDivRef = useRef<HTMLDivElement>(null);

    // NOTE: Core Memo Triggers
    const ID = useMemo(() => {
        if (idProp) return idProp;
        return uuid();
    }, [idProp]);

    const DISABLED = useMemo(() => {
        const isDisabled = typeof disabledProp === 'boolean' && disabledProp;
        if (isDisabled) setSelectActive(false);
        return isDisabled;
    }, [disabledProp]);

    const defaultValueSelected = useMemo(() => {
        return multiProp ? selection.includes('') && selection.length <= 1 : selection === '';
    }, [multiProp, selection]);

    const optionClickMulti = useCallback(
        (optionValue, selection: string[]) => {
            let newSelectedValue: string[];
            if (optionValue === '' && selection.includes('') && selection.length >= 2) {
                newSelectedValue = selection.filter((value: string) => value !== '');
            } else if (optionValue === '') {
                newSelectedValue = [''];
            } else if (selection.includes(optionValue)) {
                const newItems = selection.filter((value: any) => value !== optionValue);
                if (newItems.length < 1) newItems.push('');
                newSelectedValue = newItems;
            } else {
                const newItems = selection.filter((value: string) => value !== '' && value !== VARIOUS_VALUES_KEY);
                newItems.push(optionValue);
                newSelectedValue = newItems;
            }
            setSelection(newSelectedValue);
            if (onChangeProp) onChangeProp(newSelectedValue as any);
        },
        [onChangeProp]
    );

    const optionClickSingle = useCallback(
        (optionValue) => {
            setSelection(optionValue);
            setSelectActive(false);
            setInputStyle(false);
            if (onChangeProp) onChangeProp(optionValue);
        },
        [onChangeProp]
    );

    // Functions
    const optionClick = useCallback(
        (optionValue) => {
            if (multiProp === true) optionClickMulti(optionValue, selection as string[]);
            else optionClickSingle(optionValue);
        },
        [selection, multiProp, optionClickMulti, optionClickSingle]
    );

    const optionsElements = useMemo(() => {
        if (!options) return [];
        const lowercCaseSearchTerm = searchTerm.toLowerCase();
        const optionsList = options.filter((option) => {
            const optionIsSelected = multiProp ? selection.includes(option.value) : selection === option.value;
            const hidden = option.hidden !== undefined ? option.hidden : false;
            const isSelected = optionIsSelected;
            const inSearch =
                option.text?.toLowerCase().includes(lowercCaseSearchTerm) ||
                option.value?.toLowerCase().includes(lowercCaseSearchTerm) ||
                option.altValue?.toLowerCase().includes(lowercCaseSearchTerm);
            return inSearch && (hidden === false || (option.hidden === true && isSelected)) ? true : false;
        });

        const OpenGroupListItem = (props: { index: number; item: InfiniteListItem | InfiniteListItemAlt | string; style: StyleRecord }) => {
            const { style, item } = props as { style: StyleRecord; item: InfiniteListItem | InfiniteListItemAlt };
            const optionIsSelected = multiProp ? selection.includes(item.value) : selection === item.value;
            return (
                <OptionBox key={`select_${ID}_${item.value}`} onClick={() => optionClick(item.value)} active={optionIsSelected} style={{ ...style }}>
                    <Text color="INPUT_FG" size="1.2rem" weight="600" style={{ whiteSpace: 'no-wrap', width: '100%' }}>
                        {item.text}
                    </Text>
                </OptionBox>
            );
        };

        return (
            <OptionsList style={{ height: Math.min(optionsList.length * 40 + 10, 200) }}>
                <ContentList style={{ padding: 0, height: '100%' }}>
                    <ContentListList listItem={OpenGroupListItem} listItems={optionsList} listItemHeight={40} />
                </ContentList>
            </OptionsList>
        );
    }, [ID, multiProp, optionClick, options, searchTerm, selection]);

    // Effect Triggers
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target! as Node)) setSelectActive(false);
        };

        const handlePosition = (event: any) => {
            setDropdownStyle();
            setInputStyle();
        };

        window.addEventListener('resize', handlePosition);
        document.addEventListener('scroll', handlePosition, true);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handlePosition, true);
            window.removeEventListener('resize', handlePosition);
        };
        // eslint-disable-next-line
    }, [containerRef]);

    useEffect(() => {
        if (value !== selection) setSelection(value);
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        setDropdownStyle();
        setInputStyle();
        // eslint-disable-next-line
    }, [optionsElements]);

    useEffect(() => {
        if (searchBoxRef.current !== null) {
            if (selectActive === true) searchBoxRef.current.focus();
            else searchBoxRef.current.blur();
        }
    }, [selectActive]);

    const getSelectedValueString = useMemo(() => {
        if (multiProp) {
            let joinedString = [];
            for (let value of selection) {
                const selectedOption = options ? options.find((option) => option.value === value) : null;
                if (selectedOption) joinedString.push(selectedOption.text);
            }
            return joinedString.join(', ');
        } else {
            const selectedOption = options ? options.find((option) => option.value === selection) : null;
            return selectedOption ? selectedOption.text : '';
        }
    }, [multiProp, options, selection]);

    const setInputStyle = (isActive?: boolean) => {
        if (containerRef.current !== null && dropdownRef.current !== null) {
            const active = isActive !== undefined ? isActive : containerRef.current!.dataset.active;
            const containerOffset = getOffset(containerRef.current);

            // Input ref stuff
            const documentWidth = containerRef.current!.ownerDocument!.defaultView!.innerWidth;
            dropdownRef.current!.style.top = `${containerOffset.top}px`;
            dropdownRef.current!.style.bottom = `unset`;
            dropdownRef.current!.style.left = `${containerOffset.left}px`;
            dropdownRef.current!.style.right = `${documentWidth - containerOffset.left - containerRef.current.offsetWidth}px`;
            // eslint-disable-next-line
            if (!active || active == 'false') return;

            const distanceFromBottom = window.innerHeight - containerOffset.top;
            const minHeightContainer = 300;
            if (distanceFromBottom < minHeightContainer) {
                dropdownRef.current.style.top = `${containerOffset.top - dropdownRef.current.offsetHeight + 1 + containerRef.current.offsetHeight}px`;
                dropdownRef.current.style.bottom = `unset`;
            }
        }
    };

    const setDropdownStyle = () => {
        if (
            containerRef.current !== null &&
            searchBoxRef.current !== null &&
            selectBottomDivRef.current !== null &&
            selectTopDivRef.current !== null &&
            optionsRef.current !== null
        ) {
            const offset = getOffset(containerRef.current);
            const distanceFromBottom = window.innerHeight - offset.top;
            const optionHeight = 50;
            const maxHeightContainer = 400;
            const minHeightContainer = 300;
            const maxHeight =
                window.innerHeight - offset.top < maxHeightContainer
                    ? `${window.innerHeight - offset.top - optionHeight * 2}px`
                    : `${maxHeightContainer - optionHeight}px`;

            if (distanceFromBottom < minHeightContainer) {
                selectBottomDivRef.current.style.display = 'block';
                selectTopDivRef.current.style.display = 'none';

                searchBoxRef.current.style.order = '2';

                optionsRef.current.style.maxHeight =
                    offset.top < maxHeightContainer ? `${offset.top - optionHeight}px` : `${maxHeightContainer - optionHeight}px`;
            } else {
                selectBottomDivRef.current.style.display = 'none';
                selectTopDivRef.current.style.display = 'block';

                searchBoxRef.current.style.order = 'initial';

                optionsRef.current.style.maxHeight = maxHeight;
            }
            setInputStyle();
        }
    };

    const onClickInputBox = () => {
        if (!DISABLED) {
            const newState = !selectActive;
            // Activate
            setSelectActive(newState);

            // Set styling
            setDropdownStyle();
            setInputStyle(newState);

            if (onFocusProp) onFocusProp();
        }
    };

    return (
        <InputContainer style={style && style}>
            <Label label={label} id={ID} labelOptional={labelOptional} disabled={DISABLED} />
            <SelectMultiContainer ref={containerRef} data-active={selectActive} disabled={DISABLED}>
                {error && <FormError>{error}</FormError>}
                <InputBox onClick={() => onClickInputBox()} disabled={DISABLED} active={selectActive}>
                    <Text color={defaultValueSelected || DISABLED ? 'INPUT_PLACEHOLDER' : 'INPUT_FG'} size="1.2rem" weight="600" style={{ width: '100%' }}>
                        {getSelectedValueString}
                    </Text>
                </InputBox>

                <SelectDropdown ref={dropdownRef} active={selectActive}>
                    <SearchBox ref={searchBoxRef} placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <OptionsBox ref={optionsRef}>
                        <OptionsBoxDivider ref={selectTopDivRef} />
                        {optionsElements}
                        <OptionsBoxDivider ref={selectBottomDivRef} />
                    </OptionsBox>
                </SelectDropdown>
            </SelectMultiContainer>
        </InputContainer>
    );
}
