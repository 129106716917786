import styled from 'styled-components';
import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import { StyleRecord } from '../types/UtilTypes';

export const Button = styled.button`
    outline: none;
    border: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex-shrink: 0;
    transition: var(--transTime) opacity, var(--transTime) background-color !important;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`;

export const ButtonTrans = styled(Button)`
    padding: 0.7rem 2rem;
    color: ${({ theme }) => theme.MAIN_FG};
    font-size: min(3vw, 1.4rem);
    font-weight: 600;
    white-space: nowrap;

    border: 1px solid ${({ theme }) => theme.BUTTON_DEFAULT_BORDER};
    &:hover:enabled {
        background-color: ${({ theme }) => theme.BUTTON_DEFAULT_HOVER};
    }
    &:active:enabled {
        background-color: ${({ theme }) => theme.BUTTON_DEFAULT_PRESSED};
    }
`;

const StyledButtonColor = styled.button<{
    colorFill: string;
    colorBorder: string;
    colorHover: string;
    fillStroke: boolean;
    colorContent: string;
    colorActive: string;
    isSquare: boolean;
    fillSVG: boolean;
    backgroundAsBorder: boolean;
}>`
    ${(props) => props.isSquare && `width: 3rem`};
    height: 3rem;

    padding: ${(props) => (props.isSquare ? '0.6rem' : '0.8rem 2rem')};
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 2px;

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

    // Backgrounds button
    transition: var(--transTime) opacity, var(--transTime) background-color !important;
    background-color: ${({ theme, backgroundAsBorder, colorFill }) => (backgroundAsBorder ? 'transparent' : theme[colorFill])};
    border: 1px solid ${({ theme, backgroundAsBorder, colorFill, colorBorder }) => (backgroundAsBorder ? theme[colorFill] : theme[colorBorder])};
    &:hover:enabled {
        background-color: ${({ theme, backgroundAsBorder, colorHover }) => (backgroundAsBorder ? 'transparent' : theme[colorHover])};
        border: 1px solid ${({ theme, backgroundAsBorder, colorHover, colorBorder }) => (backgroundAsBorder ? theme[colorHover] : theme[colorBorder])};
    }
    &:active:enabled {
        background-color: ${({ theme, backgroundAsBorder, colorActive }) => (backgroundAsBorder ? 'transparent' : theme[colorActive])};
        border: 1px solid ${({ theme, backgroundAsBorder, colorActive, colorBorder }) => (backgroundAsBorder ? theme[colorActive] : theme[colorBorder])};
    }

    // Content button
    svg {
        width: auto;
        height: 100%;
        max-height: 1.4rem;
        ${(props) => props.fillSVG && props.fillStroke && `stroke: ${props.theme[props.colorContent]};`}
        ${(props) => props.fillSVG && !props.fillStroke && `fill: ${props.theme[props.colorContent]};`}
    }
    font-size: 1.2rem;
    font-weight: 600;
    white-space: nowrap;
    color: ${(props) => props.theme[props.colorContent]};
`;
export enum ButtonColorTypes {
    DEFAULT = 'DEFAULT',
}

interface ButtonColorProps {
    color: ButtonColorTypes;
    style?: StyleRecord;
    children?: ReactNode;
    onClick?: (e: MouseEvent) => void;
    fillStroke?: boolean;
    isSquare?: boolean;
    fillSVG?: boolean;
    disabled?: boolean;
    backgroundAsBorder?: boolean;
}

export function ButtonColor(props: ButtonColorProps) {
    const { color, style, children, onClick, fillStroke, isSquare, fillSVG, disabled, backgroundAsBorder } = props;

    const { colorFill, colorHover, colorActive, colorContent, colorBorder } = useMemo(() => {
        const colorVar = color ?? 'DEFAULT';
        return {
            colorFill: `BUTTON_${colorVar}`,
            colorBorder: `BUTTON_${colorVar}_BORDER`,
            colorHover: `BUTTON_${colorVar}_HOVER`,
            colorActive: `BUTTON_${colorVar}_PRESSED`,
            colorContent: `BUTTON_${colorVar}_CONTENT`,
        };
    }, [color]);

    const onClickButton = (e: MouseEvent) => {
        if (onClick) onClick(e);
    };

    return (
        <StyledButtonColor
            fillSVG={fillSVG ?? true}
            style={style && style}
            colorFill={colorFill}
            colorHover={colorHover}
            colorActive={colorActive}
            colorContent={colorContent}
            colorBorder={colorBorder}
            onClick={onClickButton}
            fillStroke={fillStroke ?? false}
            isSquare={isSquare ?? false}
            disabled={typeof disabled === 'boolean' ? disabled : false}
            backgroundAsBorder={backgroundAsBorder}
        >
            {children}
        </StyledButtonColor>
    );
}