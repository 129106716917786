import styled from 'styled-components';

export const StyledPage = styled.main`
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    background: ${({ theme }) => theme.MAIN_BG};
    background-size: cover;
`;
