import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyledPage } from './components/Page';
import { ReactComponent as CaspianLogo } from './assets/caspian_qt_logo.svg';
import styled from 'styled-components';
import { Text } from './components/Text';
import { FlexCol } from './components/Flex';
import { cgDescKey, cgIdKey, cgTokenKey, cgUserKey } from '.';
import { useCookies } from 'react-cookie';
import { FormRow } from './form/FormRow';
import { Input } from './form/Input';
import { Textarea } from './form/Textarea';
import { ButtonColor, ButtonColorTypes } from './components/Button';
import { useSendMessage } from './hooks/useSendMessage';
import { Products } from './Products';
import { StatusComponent } from './components/StatusComponent';
import { Nav, PageTypes } from './components/Nav';
import { Announcements } from './Announcements';
import { ProductsRemote } from './ProductsRemote';

const StyledContent = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: clamp(20px, 8vw, 80px) 20px;
`;

const StyledIcon = styled.div`
    height: 16vw;
    max-height: 120px;
    display: flex;
    justify-content: center;
    svg {
        height: 100%;
        width: auto;
    }
`;

export function App() {
    const { loading, success, onClickSendMessage, error } = useSendMessage();

    const [pageType, setPageType] = useState<PageTypes>(PageTypes.ANNOUNCEMENTS);

    const [cookies, setCookie, removeCookie] = useCookies([cgTokenKey, cgUserKey, cgIdKey, cgDescKey]);

    const [webhookURL, setWebhookURL] = useState<string>('');
    const [cookGroupMessage, setCookGroupMessage] = useState<string>('');
    const [cookGroupMessageTitle, setCookGroupMessageTitle] = useState<string>('');

    useEffect(() => {
        if (success?.trim()?.length) {
            setCookGroupMessage('');
            setCookGroupMessageTitle('');
        }
    }, [success]);

    return (
        <StyledPage>
            <StyledContent>
                <StyledIcon>
                    <CaspianLogo />
                </StyledIcon>
                <FlexCol style={{ marginTop: 'clamp(40px, 8vw, 80px)' }}>
                    <FormRow columns="1fr">
                        <Text color="MAIN_FG" size="min(3.6vw, 2.2rem)" weight="700" multiLiner={true}>
                            Welcome, {cookies?.[cgUserKey]}!
                        </Text>
                    </FormRow>
                    <Nav selectedPage={pageType} setPage={setPageType} />
                    {pageType === PageTypes.ANNOUNCEMENTS && <Announcements />}
                    {pageType === PageTypes.PRODUCT_MANAGEMENT && <Products />}
                    {pageType === PageTypes.PRODUCT_REMOTE && <ProductsRemote />}
                </FlexCol>
            </StyledContent>
        </StyledPage>
    );
}
