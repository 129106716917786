export const theme = {
    FONTS_PRIMARY: "'Open Sans', sans-serif",
    MAIN_BG: 'linear-gradient(303deg, #051619 0%, #161617 20%, #0a2d32 84%)',
    MAIN_FG: '#FAFAFA',
    PINK_FG: '#FF3562',
    GREEN_FG: '#00d296',
    MAIN_FG_THEME: '#2297a9',
    MAIN_BORDER: 'rgba(255, 255, 255, 0.2)',
    SECOND_FG: 'rgba(255, 255, 255, 0.4)',

    BUTTON_DEFAULT: 'rgba(15, 15, 15, 0.5)',
    BUTTON_DEFAULT_BORDER: 'rgba(255, 255, 255, 0.1)',
    BUTTON_DEFAULT_HOVER: 'rgba(45, 45, 45, 0.5)',
    BUTTON_DEFAULT_PRESSED: 'rgba(30, 30, 30, 0.5)',
    BUTTON_DEFAULT_CONTENT: '#FAFAFA',

    NAV_BUTTON_BG: 'transparent',
    NAV_BUTTON_BG_ACTIVE: 'rgba(34,151,169,0.25)',
    NAV_BUTTON_BORDER: 'rgba(255,255,255,0.1)',
    NAV_BUTTON_BORDER_ACTIVE: '#2297a9',

    INPUT_BG: 'rgba(20, 23, 24, 1)',
    INPUT_FG: 'rgba(240, 240, 240, 1)',
    INPUT_BORDER: 'rgba(80, 80, 80, 0.6)',
    INPUT_PLACEHOLDER: 'rgba(120, 120, 120, 0.6)',
    INPUT_BORDER_ACTIVE: '#2297a9',
    INPUT_LABEL: '#FAFAFA',
    INPUT_LABEL_OPTIONAL: 'rgba(255, 255, 255, 0.4)',

    SWITCH_TOGGLE_BG: '#1f1e22',
    SWITCH_TOGGLE_BG_ACTIVE: '#2297a9',
    SWITCH_TOGGLE_FG: '#848484',
    SWITCH_TOGGLE_FG_ACTIVE: '#FAFAFA',
};
