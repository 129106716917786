import { Text } from './Text';

export function StatusComponent(props: { success?: string | undefined; error?: Error | undefined }) {
    const { success, error } = props;
    return (
        <>
            {success && (
                <Text color="GREEN_FG" size="1.3rem" weight="600" multiLiner={true} style={{ textAlign: 'left', marginTop: -10, marginBottom: 20 }}>
                    {success}
                </Text>
            )}
            {error instanceof Error && (
                <Text color="PINK_FG" size="1.3rem" weight="600" multiLiner={true} style={{ textAlign: 'left', marginTop: -10, marginBottom: 20 }}>
                    {error?.message}
                </Text>
            )}
        </>
    );
}
