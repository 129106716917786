import { useEffect, useState, useMemo, ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import { Label } from './Label';
import { FormError } from './FormError';
import _ from 'lodash';
import { DateInputValue, InputHeight } from './FormTypes';
import { v4 as uuid } from 'uuid';
import { match } from 'ts-pattern';
import { ReactComponent as IconArrow } from './../assets/icon_arrow_open.svg';
import { StyleRecord } from '../types/UtilTypes';
import { FlexCol, FlexRowMiddle } from '../components/Flex';
import { Text } from '../components/Text';
import { MONTHS_SHORT } from '../constants/Month';
import { GridCol } from '../components/Grid';
import { leadZeroNumber } from '../scripts/scripts_number';
import { Icon } from '../components/Icon';

const InputContainer = styled.div<{ disabled: boolean }>`
    width: 100%;
    position: relative;
    ${({ disabled }) => disabled && 'opacity: 0.4;'}
`;

const StyledInput = styled.input<{ customInputHeight?: string; hasError: boolean }>`
    width: 100%;
    height: ${({ customInputHeight }) => customInputHeight ?? InputHeight};
    padding: 0.7rem 1.5rem;

    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.PINK_FG : theme.INPUT_BORDER)};
    background: ${({ theme }) => theme.INPUT_BG};

    outline: none;

    color: ${({ theme }) => theme.INPUT_FG};
    font-size: 1.2rem;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:disabled {
        cursor: not-allowed;
    }
    &::placeholder {
        color: ${({ theme }) => theme.INPUT_PLACEHOLDER};
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.INPUT_BORDER_ACTIVE};
    }
`;

interface DateInputProps {
    value: DateInputValue;
    onChange: (value: DateInputValue) => void;

    id?: string;
    placeholder?: string;
    style?: StyleRecord;
    styleInput?: StyleRecord;
    disabled?: boolean;
    error?: string;
    label?: string;
    labelOptional?: string;
    dropdownDisabled?: boolean;
    onFocus?: () => void;
    onChangeMap?: (value: DateInputValue) => DateInputValue;
    customInputHeight?: string;
}

export function DateInput(props: DateInputProps) {
    const {
        id,
        placeholder,
        style,
        styleInput,
        value,
        disabled,
        error,
        dropdownDisabled,
        label: labelProp,
        labelOptional,
        onFocus: onFocusProp,
        onChange: onChangeProp,
        onChangeMap: onChangeMapProp,
        customInputHeight,
    } = props;

    const getInputStringFormat = (v: DateInputValue): string => {
        return `${v.year}-${leadZeroNumber(v.month + 1, 2)}-${leadZeroNumber(v.date)}`;
    };

    const validateInputStringFormat = (v: string): string => {
        const regexFullDate =
            /(^(19|[2-9][0-9])\d\d[-](((0[1-9]|1[012])[-](0[1-9]|1[0-9]|2[0-8]))|((0[13578]|1[02])[-](29|30|31))|((0[4,6,9]|11)[-](29|30)))$)|(^(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)[-]02[-]29$)/;
        const parsedDate = v.match(regexFullDate);
        if (parsedDate === null) {
            return getInputStringFormat(value);
        } else {
            return v;
        }
    };

    const inputRef = useRef<HTMLDivElement>(null);
    const [inputString, setInputString] = useState<string>(getInputStringFormat(value));
    const [inputFocused, setInputFocused] = useState<boolean>(false);

    // NOTE: Memo Triggers
    const disabledValue = useMemo(() => {
        const isDisabled = typeof disabled === 'boolean' && disabled;
        return isDisabled;
    }, [disabled]);

    const idValue = useMemo(() => {
        if (id) return id;
        return uuid();
    }, [id]);

    // NOTE: Functions
    const onInputFocus = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFocused(true);
        if (onFocusProp) onFocusProp();
    };

    const onInputBlur = (e: ChangeEvent<HTMLInputElement>, filteredInputString?: string) => {
        // setInputFocused(false);
        const parsedInputString = validateInputStringFormat(filteredInputString ?? inputString);
        const [year, month, date] = parsedInputString.split('-');
        setValue({
            year: parseInt(year),
            month: parseInt(month) - 1,
            date: parseInt(date),
        });
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const leapYears = ['2020', '2024', '2028'];
        const longMonths = ['01', '03', '05', '07', '08', '10', '12'];
        let filteredValue = e.target.value.replaceAll(/[^\d-]+/g, '');
        let newValue = '';
        let stopLoop = false;
        for (let i = 0; i < filteredValue.length; i++) {
            switch (i) {
                case 0:
                    if (filteredValue[i] !== '2') {
                        stopLoop = true;
                        break;
                    }
                    newValue += filteredValue[i];
                    break;
                case 1:
                    if (filteredValue[i] !== '0') {
                        stopLoop = true;
                        break;
                    }
                    newValue += filteredValue[i];
                    break;
                case 2:
                    if (filteredValue[i] !== '2') {
                        stopLoop = true;
                        break;
                    }
                    newValue += filteredValue[i];
                    break;
                case 3:
                    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8'].includes(filteredValue[i])) {
                        stopLoop = true;
                        break;
                    }
                    newValue += filteredValue[i];
                    break;
                case 4:
                    if (filteredValue[i] === '-') {
                        newValue += '-';
                    } else if (filteredValue[i] === '0' || filteredValue[i] === '1') {
                        newValue += `-${filteredValue[i]}`;
                    } else {
                        stopLoop = true;
                        break;
                    }
                    break;
                case 5:
                    if (filteredValue[i] !== '0' && filteredValue[i] !== '1') {
                        stopLoop = true;
                        break;
                    }
                    newValue += filteredValue[i];
                    break;
                case 6:
                    if (filteredValue[5] === '0' && ['0', '-'].includes(filteredValue[i])) {
                        stopLoop = true;
                        break;
                    }
                    if (filteredValue[5] === '1' && !['0', '1', '2'].includes(filteredValue[i])) return;
                    newValue += filteredValue[i];
                    break;
                case 7:
                    if (filteredValue[i] === '-') {
                        newValue += '-';
                    } else if (
                        ['0', '1', '2'].includes(filteredValue[i]) ||
                        (filteredValue[i] === '3' && longMonths.includes(`${filteredValue[5]}${filteredValue[6]}`))
                    ) {
                        newValue += `-${filteredValue[i]}`;
                    } else {
                        stopLoop = true;
                        break;
                    }
                    break;
                case 8:
                    if (
                        !['0', '1', '2'].includes(filteredValue[i]) &&
                        filteredValue[i] === '3' &&
                        !longMonths.includes(`${filteredValue[5]}${filteredValue[6]}`)
                    ) {
                        stopLoop = true;
                        break;
                    }
                    newValue += `${filteredValue[i]}`;
                    break;
                case 9:
                    const year = `${filteredValue[0]}${filteredValue[1]}${filteredValue[2]}${filteredValue[3]}`;
                    const month = `${filteredValue[5]}${filteredValue[6]}`;
                    if (month === '02') {
                        if (!leapYears.includes(year) && filteredValue[8] === '2' && filteredValue[i] === '9') {
                            stopLoop = true;
                            break;
                        }
                    }
                    if (longMonths.includes(month) && filteredValue[8] === '3') {
                        if (longMonths.includes(month) && !['0', '1'].includes(filteredValue[i])) {
                            stopLoop = true;
                            break;
                        }
                        if (!longMonths.includes(month) && filteredValue[i] !== '0') {
                            stopLoop = true;
                            break;
                        }
                    }
                    newValue += `${filteredValue[i]}`;
                    break;
                default:
                    break;
            }
            if (stopLoop) break;
        }
        setInputString(newValue);
        if (filteredValue.length >= 10) {
            onInputBlur(e, filteredValue);
        }
    };

    const setValue = (v: DateInputValue) => {
        const mappedValue = onChangeMapProp ? onChangeMapProp(v) : v;
        setInputString(getInputStringFormat(mappedValue));
        onChangeProp(mappedValue);
    };

    const labelComponent = useMemo(() => {
        if (labelProp) return <div style={{ opacity: disabledValue ? '0.4' : '1' }}>{labelProp}</div>;
        else return null;
    }, [labelProp, id, disabledValue]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target! as Node)) {
                setInputFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [inputRef]);

    useEffect(() => {
        if (disabledValue && inputFocused) {
            setInputFocused(false);
        }
    }, [disabledValue, inputFocused]);

    useEffect(() => {
        setInputString(getInputStringFormat(value));
    }, [value]);

    return (
        <InputContainer style={style && style} disabled={disabledValue} ref={inputRef}>
            <Label label={labelProp} id={idValue} labelOptional={labelOptional} disabled={disabledValue} />
            <StyledInput
                customInputHeight={customInputHeight}
                disabled={disabledValue}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onChange={onChange}
                value={inputString}
                id={idValue}
                placeholder={placeholder}
                style={styleInput && styleInput}
                hasError={error && disabledValue !== true}
            />
            {error && disabledValue !== true && <FormError>{error}</FormError>}
            {!disabledValue && inputFocused && dropdownDisabled !== true && (
                <DateInputDropdown hasLabel={labelComponent !== null} id={id} value={value} setValue={setValue} />
            )}
        </InputContainer>
    );
}

const StyledCalendarDropdown = styled(FlexCol)<{ hasLabel: boolean }>`
    width: 280px;
    padding: 10px 0px;
    position: absolute;
    top: ${InputHeight};
    left: 0;
    margin-top: ${({ hasLabel }) => (hasLabel ? '26px' : '5px')};
    border: 1px solid ${({ theme }) => theme.INPUT_BORDER_ACTIVE};
    background: ${({ theme }) => theme.INPUT_BG};
    z-index: 10;
`;

const StyledCalendarHeader = styled(FlexRowMiddle)`
    padding: 0 15px;
    margin-bottom: 5px;
`;

const StyledCalendarWeekdays = styled(GridCol)`
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 3px;
    text-align: center;
`;

const StyledCalendarWeekday = styled(Text)`
    padding: 5px 0;
`;

const StyledCalendarDates = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 3px;
    row-gap: 3px;
    grid-template-rows: auto;
    text-align: center;
`;

const StyledCalendarDate = styled(Text)<{ dateToday: boolean; selected: boolean }>`
    padding: 3px 0;
    cursor: pointer;
    opacity: 1;
    border: 1px solid ${({ theme, dateToday, selected }) => (selected ? theme.INPUT_BORDER_ACTIVE : dateToday ? theme.INPUT_PLACEHOLDER : 'transparent')};
`;

interface DashboardCalendarProps {
    hasLabel: boolean;
    value: DateInputValue;
    id: string;
    setValue: (date: DateInputValue) => void;
}

function DateInputDropdown(props: DashboardCalendarProps) {
    const { hasLabel, value, id, setValue } = props;

    const [viewYear, setViewYear] = useState<number>(value.year);
    const [viewMonth, setViewMonth] = useState<number>(value.month);

    const startDayOfMonth = useMemo(() => {
        const day = new Date(viewYear, viewMonth, 1).getDay();
        // NOTE: getDay() returns sunday as day 0 so we need to convert it to 0 indexed monday
        return match(day)
            .with(0, () => 6)
            .otherwise(() => day - 1);
    }, [viewYear, viewMonth]);

    const nDaysInMonth = useMemo(() => {
        // NOTE: We can do month + 1 as last december and january both have 31 days
        return new Date(viewYear, viewMonth + 1, 0).getDate();
    }, [viewYear, viewMonth]);

    const dateBoxComponents = useMemo(() => {
        const components = [];
        for (let i = 0; i < nDaysInMonth + startDayOfMonth; i++) {
            if (i >= startDayOfMonth) {
                const date = i + 1 - startDayOfMonth;
                const dateSelected = viewYear === value.year && viewMonth === value.month && date === value.date;
                const currentDate = new Date();
                const dateToday = viewYear === currentDate.getFullYear() && viewMonth === currentDate.getMonth() && date === currentDate.getDate();
                components.push(
                    <StyledCalendarDate
                        color={dateSelected ? 'INPUT_FG' : 'INPUT_PLACEHOLDER'}
                        size="1.2rem"
                        weight="600"
                        onClick={() => setValue({ year: viewYear, month: viewMonth, date: date })}
                        key={`dateinput_${id}_${viewYear}-${viewMonth}-${date}`}
                        dateToday={dateToday}
                        selected={dateSelected}
                    >
                        {date}
                    </StyledCalendarDate>
                );
            } else {
                components.push(<p key={`calendarDate_empty_${i}`} />);
            }
        }
        return components;
    }, [nDaysInMonth, startDayOfMonth, value, viewMonth, setValue, viewYear, id]);

    const increaseDisabled = useMemo(() => {
        return viewYear === 2024 && viewMonth === 11;
    }, [viewYear, viewMonth]);

    const decreaseDisabled = useMemo(() => {
        return viewYear === 2020 && viewMonth === 0;
    }, [viewYear, viewMonth]);

    const increaseMonth = () => {
        if (viewMonth === 11) {
            if (viewYear < 2024) {
                setViewMonth(0);
                setViewYear((v) => v + 1);
            }
        } else {
            setViewMonth((v) => v + 1);
        }
    };

    const decreaseMonth = () => {
        if (viewMonth === 0) {
            if (viewYear > 2020) {
                setViewMonth(11);
                setViewYear((v) => v - 1);
            }
        } else {
            setViewMonth((v) => v - 1);
        }
    };

    useEffect(() => {
        setViewYear(value.year);
        setViewMonth(value.month);
    }, [value]);

    return (
        <StyledCalendarDropdown hasLabel={hasLabel}>
            <StyledCalendarHeader>
                <Text color="MAIN_FG" size="1.3rem" weight="600">
                    {MONTHS_SHORT[viewMonth]} {viewYear}
                </Text>
                <FlexRowMiddle style={{ marginLeft: 'auto' }}>
                    <Icon
                        onClick={decreaseMonth}
                        colorStroke="MAIN_FG"
                        style={{ height: 13, marginRight: 20, cursor: decreaseDisabled ? 'default' : 'pointer', opacity: decreaseDisabled ? 0.4 : 1 }}
                    >
                        <IconArrow />
                    </Icon>
                    <Icon
                        onClick={increaseMonth}
                        colorStroke="MAIN_FG"
                        style={{
                            height: 13,
                            transform: 'rotate(180deg)',
                            cursor: increaseDisabled ? 'default' : 'pointer',
                            opacity: increaseDisabled ? 0.4 : 1,
                        }}
                    >
                        <IconArrow />
                    </Icon>
                </FlexRowMiddle>
            </StyledCalendarHeader>
            <StyledCalendarWeekdays>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    M
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    T
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    W
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    T
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    F
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    S
                </StyledCalendarWeekday>
                <StyledCalendarWeekday color="INPUT_FG" size="1.2rem" weight="600">
                    S
                </StyledCalendarWeekday>
            </StyledCalendarWeekdays>
            <StyledCalendarDates>{dateBoxComponents}</StyledCalendarDates>
        </StyledCalendarDropdown>
    );
}
