import _ from 'lodash';
import { ReactNode } from 'react';
import { IdRecord, StyleRecord } from '../types/UtilTypes';
import { FlexCol } from './Flex';
import styled from 'styled-components';
import { InfiniteList, InfiniteListArray, InfiniteListItem, InfiniteListItemAlt, InfiniteListItemComponent } from './InfiniteList';

/*****************/
/** ContentList **/
/*****************/
const StyledContentList = styled(FlexCol)`
    width: 100%;
    height: 100%;
    padding: 0 0.1rem 1rem 2rem;
    overflow: hidden;
`;

interface ContentListProps {
    children: ReactNode;
    style?: StyleRecord;
}

export function ContentList(props: ContentListProps) {
    const { children, ...rest } = props;
    return <StyledContentList {...rest}>{children}</StyledContentList>;
}

/***********************/
/** ContentListList **/
/***********************/
interface ContentListListProps {
    listItem: InfiniteListItemComponent;
    listItems: IdRecord<InfiniteListItem> | InfiniteListItem[] | InfiniteListItemAlt[] | string[];
    listItemHeight: number;
    style?: StyleRecord;
}
export function ContentListList(props: ContentListListProps) {
    const { listItem, listItems, listItemHeight, style } = props;
    if (_.isArray(listItems)) {
        return (
            <InfiniteListArray
                style={{ overflow: 'hidden', flex: '1', ...(style ? style : {}) }}
                listItem={listItem}
                items={listItems}
                itemHeight={listItemHeight}
            />
        );
    } else {
        return (
            <InfiniteList
                style={{ overflow: 'hidden', flex: '1', ...(style ? style : {}) }}
                listItem={listItem}
                items={listItems}
                itemHeight={listItemHeight}
            />
        );
    }
}
