import { DateInputValue } from '../form/FormTypes';

export function getIsBetweenDates(start: Date, end: Date, date: Date) {
    return (
        start.getFullYear() >= date.getFullYear() &&
        end.getFullYear() <= date.getFullYear() &&
        start.getMonth() >= date.getMonth() &&
        end.getMonth() <= date.getMonth() &&
        start.getDate() >= date.getDate() &&
        end.getDate() <= date.getDate()
    );
}

export function getIsSameDate(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export function getDateInputValueFromDate(date: string | Date): DateInputValue {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return { year: dateObj.getFullYear(), month: dateObj.getMonth(), date: dateObj.getDate() };
}

export function dateInputSmallerThan(d1: DateInputValue, d2: DateInputValue): boolean {
    return d2.year < d1.year || (d2.year >= d1.year && d2.month <= d1.month && d2.date < d1.date);
}
